// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';


function Hero() {
    return (
        <>
            <section id="hero" className="d-flex align-items-center">
    <div className="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
      <div className="row">
        <div className="col-lg-8">
          <h1>Welcome to <span>2020 Group LLC</span></h1>
          <h2>Providing global solutions for more than 9 years!</h2>

          <div className="btns">
            <a href="#services" className="btn-menu animated fadeInUp scrollto">Our Services</a>
            <a href="#contact" className="btn-book animated fadeInUp scrollto">Request a Call</a>
          </div>
        </div>
        <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
          <a href="https://www.youtube.com/watch?v=u6BOC7CDUTQ" className="glightbox play-btn"></a>
        </div>

      </div>
    </div>
  </section>
        </>
    )

}

export default Hero;